
@media only screen and (max-width: 600px) {
.HorizontalHeader{
    display: grid;
    .nav-tabs{
        order: 2;
    }
    .tab-content{
        order: 1;
    }
    .nav-pills .nav-link.active {
        background-color: unset;
        color: #f27e3f;
        img.icon{
            display:none;
        }
        img.iconHover{
            display:block;
        }
    }

}

.horizontal-header{
    text-align: center;
    padding: 10px 5px;
    background: #45485f;
    border-radius: 15px;
    margin-left: 0;
    padding-bottom: 0px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 15px;
    img.icon,img.iconHover{
       width: 80%;
    }
    a.nav-link {
        padding: 0;
        margin: 0px 9px;
    }
}

.horizontal-header.nav-pills .nav-link.active {
        background-color: unset;
        color: #f27e3f;
        img.icon{
            display:none;
        }
        img.iconHover{
            display:block;
        }
    }
.horizontal-header.nav-pills .nav-link {
    background-color: unset;
    img.icon{
        display:block;
    }
    img.iconHover{
        display:none;
    }
}
}

@media only screen and (min-width: 600px) {
.horizontal-header{
    display: none;
}
}


