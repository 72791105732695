:root {
    --cnss-color: #E8EEFE;
    --width-70: 70%;
    --succes:#198754;
    --primary:#4a5182;
    --primary-1:#45485f;
    --gray:#919191;
    --second-primary-cl:#495be1;
    --orange:#f3864b;
    --light:#f0f2fd;
    --xs:12px;
    --small:14px;
    --medium : 15px;
    --large : 20px;
  }