
.succes{
  background: #0da781;
  border: 3px solid;
}
.succes:hover{
	background: #0da781b0;
	border: 3px solid;
  }

.form-container {
	width: 100vw;
	height: 100vh;
	background-color: #7b2cbf;
	display: flex;
   	justify-content: center;
	align-items: center;
}
.upload-files-container {
	background-color: #f7fff7;
	width: 420px;
	padding: 30px 60px;
	border-radius: 40px;
	display: flex;
   	align-items: center;
   	justify-content: center;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
}
.drag-file-area {
	border: 2px dashed #7b2cbf;
	border-radius: 40px;
	margin: 10px 0 15px;
	padding: 30px 50px;
	width: 350px;
	text-align: center;
}
.drag-file-area .upload-icon {
	font-size: 50px;
}
.drag-file-area h3 {
	font-size: 26px;
	margin: 15px 0;
}
.drag-file-area label {
	font-size: 19px;
}
.drag-file-area label .browse-files-text {
	color: #7b2cbf;
	font-weight: bolder;
	cursor: pointer;
}
.browse-files span {
	position: relative;
	top: -25px;
}
.default-file-input {
	opacity: 0;
}
.cannot-upload-message {
	background-color: #ffc6c4;
	font-size: 17px;
	display: flex;
	align-items: center;
	margin: 5px 0;
	padding: 5px 10px 5px 30px;
	border-radius: 5px;
	color: #BB0000;
	display: none;
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.cannot-upload-message span, .upload-button-icon {
	padding-right: 10px;
}
.cannot-upload-message span:last-child {
	padding-left: 20px;
	cursor: pointer;
}
.file-block {
	color: #f7fff7;
	background-color: #7b2cbf;
  	transition: all 1s;
	width: 390px;
	position: relative;
	display: none;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0 15px;
	padding: 10px 20px;
	border-radius: 25px;
	cursor: pointer;
}
.file-info {
	display: flex;
	align-items: center;
	font-size: 15px;
}
.file-icon {
	margin-right: 10px;
}
.file-name, .file-size {
	padding: 0 3px;
}
.remove-file-icon {
	cursor: pointer;
}
.progress-bar {
	display: flex;
	position: absolute;
	bottom: 0;
	left: 4.5%;
	width: 0;
	height: 5px;
	border-radius: 25px;
	background-color: #4BB543;
}
.upload-button {
	font-family: 'Montserrat';
	background-color: #7b2cbf;
	color: #f7fff7;
	display: flex;
	align-items: center;
	font-size: 18px;
	border: none;
	border-radius: 20px;
	margin: 10px;
	padding: 7.5px 50px;
	cursor: pointer;
}

h2 {
	font-size: 2em;
	margin-top: 20px;
}

.switch {
	position: relative;
	display: inline-block;
	width: 21%;
	height: 30px;
  margin-bottom:10px;
}

.switch input {
	visibility: hidden;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f0f2fd;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
  height: 23px;
  width: 25px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

input:checked + .slider {
	background-color: #00aa63;
}

input:focus + .slider {
	box-shadow: 0 0 1px #00aa63;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(25px);
}

.slider.round {
	border-radius: 50px;
}

.slider.round:before {
	border-radius: 50%;
}

.lights-on {
	background-color: #00aa63;
	width: 100vw;
	height: 100vh;
}
.toggle-title{
  color: #aebae0;
  font-weight: 400;
  margin-bottom: 10px;
}
.right-container{
  padding-left: 50px;
  border-left: 1px solid #e2e6fa;
}
.left-container{
 padding: 0;
}
.toggle{
  width: 6%;
  margin-bottom: 5% !important;
}
.alert_{
  padding: 6px;
  font-size: 10px;
  text-transform: uppercase;
  color: #80808069;
  border: unset;
  background: #e9effe;
  margin-bottom: 15% !important;
}
//input file

.t{
  visibility: hidden;
  width: 1px;
  height: 1px;
}
 .btn1 {
  width: 100%;
  background-color: #fff;
  border-color: #ccc;
  color: #333;
  padding: 30px;
  border: 1px dashed #ccc;
}
 .file-selected {
    font-size: 10px;
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 5px;
}
 .wrap {
  display: table;
  width: 100%;
  height: 100%;
}

.valign-middle {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.text-1{
  font-size: 18px;
  font-weight: 600;
}
.text-2{
  font-size: 17px;
}
.text-3{
  font-size: 14px;
  color: grey;
}
.text-4{
  color: #f27e3f;
  font-size: 14px;
}
.sr-only{
	color :#74768b
}
.wrap.file{
	padding: 6%;
    border: 5px solid #e9eefe;
    border-style: dashed;
}

form div div.methode_alert {
    font-size: 12px;
    padding: 5px;
    margin-top: 5px;
}

.view-payment {
	position: relative;
	.textarea-col {
		margin: 0;
		div {
			height: 100%;
			.payment-view-textarea {
				height: 100%;
			}
		}
	}

	.payment-mode {
		// background-color: aliceblue;
		border: 2px solid #eaeffe;
		border-radius: 5px;
		display: flex;
    	flex-wrap: wrap;

		label.form-label {
			width: 100%;
			font-size: 12px;
			color: #afb6e5;

		}
		.form-check-input {
			display: none;
		}
		label.form-check-label {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 15px;
		}

		.radio-seected label.form-check-label {
			border: 2px solid #eaeffe;
			background-color: #eaeffe;
			border-radius: 5px;
		}

	}
}

.view-payment-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999; /* Ensure the overlay is above other content */
}


  

.my-dropzone {
	border: 2px dashed #979292;
	border-radius: 4px;
	background-color: #fafafa;
	padding: 20px;
	text-align: center;
	cursor: pointer;
	margin-bottom: 9px !important;
  }

  input[type=file]::file-selector-button {
	/* Add properties here */
	display: none;
  }
  
  
  .customFileUpload {
	margin-left: 73px;
  }
  