@media only screen and (max-width: 600px) {
    

    .body .modal-style .modal-content{
        width: 100% !important;
    }
    .title-form {
        font-size: 25px;
    }
    .Dash-tabs{
        gap: 10px;
    }
    .card-content.desktop{
        display: none;
      }
    .card-content{
      margin: 10px !important;
      overflow: scroll;
      height: 595px;
      padding: 5px !important;
      }
    .top-header {
        display: none;
    }
    .vertical-header{
        display: none;
    }
    .col{
        width: 50%;
    }
    .nav-container.mobil{
        border-radius: unset;
    }
    .parent{
        margin: 0 !important;
        .bar-top{
            flex-direction: column-reverse;
            .first{
                width: 100%;
            }
        }
        .back-btn{
            margin: 0 !important;
            .employeur{
                display: none;
            }
        
        }
    }

    .Employeur-tabs .nav-item .nav-link {
        padding: 10px 8px;
    }
   
    .card-content.mobil{
        display: block;
        border: unset;
        box-shadow: rgb(233 239 254) 0px 5px 15px;
        padding: 10px;
        margin-top: 5px;
        height: 585px;
        overflow-y: scroll;
        margin: 0px 10px 0px 10px;
        overflow-x: hidden;
        .row{
            gap: 10px;
        }
        .btns-bar {
            text-align: center;
            margin-bottom: 10px;
            margin-top: 10px;
            button{
                border-radius: 5px !important;
                padding: 15px 20px;
                width: 100%;
            }
        }
    }
    .cards.mobil{
        display: block;
        padding-bottom: 15px;
        

        img.iconHover,img.icon{
            margin-left: -15px;
            margin-right: 25px;
           }
        .card-body{
            margin-top: -25px;
            padding-right: 5px !important;
            padding-top: 0px;
            margin-left: 10px;
            padding: 0;
            .card-title{
               font-size: medium;
            }
          
        }
        
    }
    .col-md-6.col-sm-12{
        padding-left: 0px;
    }
    .add-dec.nav-tabs{
        margin: 0px 15px;
        margin-bottom: 25% !important;
    }
    .card-content .tab-content {
        padding: 0;
    }
    .scrol-x-t{
        overflow: scroll;
        width: 100%;
        margin: 10px 0 !important;
    }
    .card-content .btns-bar {
        .paye,.add-pay,.salary,.demande{
            border-radius: 5px !important;
        }
        button{
            padding: 15px 10px;
            border-radius: 5px;
        }
        text-align: center;
        margin-bottom: 10px;
        
            .btns-declaration {
                display: grid;
            }
            .add-demand-btn{
                width: 100%;
                padding: 15px 0px;
                border-radius: 5px;
            }
    }
    .btns-declaration.last{
        background: white !important;
        color: #a6b0e5;
        border: 1px solid #a6b0e5 !important;
    }
    .modal-footer{
        flex-direction: column;
    }

    .form-control{
        width: 100% !important;
        margin: 0 !important;
    }
    .card-content.mobil .row{
        margin: 0;
    }
    .card-content.mobil .row>div{
       /* padding: 10px;*/
        margin: 0;
    }
    .succes{
        width: 100%;
        background: #0da781;
        border: unset;
    }
    .form-row{
        .col{
            padding: 0;
        }
    }

    .table{
        font-size: 14px;
        flex-flow: column;
        width:760px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-x: scroll;
    }
    .search-mobil{
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .toggle{
        text-align: right;
        width: 100%;
    }
    .card-content.mobil .btns-bar button.btn-100{
        width: 100%;
    }
    .matricule.back{
        display: none;
    }
    .padding-0 .form-row .col-md-6.col-sm-12{
        padding: 0;

    }
    .padding-0 .form-row .input-mobil{
            margin-bottom: 0 !important;
            
        }

   .modal-dialog{
        margin: 10px !important;
    }
    .download.btn-light{
        width: 100%;
    }
    .card-content .salary-container{
        width: 100%;
        .btns-bar button{
           width: 100%;
           border-radius: 5px;
        }
    }
 
 
}

