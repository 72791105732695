
@media only screen and (max-width: 600px) {
    .btns-bar.desktop{
        display: none;
    }
    .btns-declaration{
        width: 30%;
        border: 1px solid #f27e3f;
        display: flex;
        margin: 0 !important;
        font-size: 12px;
        padding: 15px 10px;
        border-radius: 5px;
    }
    .btns-bar.mobil{
        display: flex;
        gap: 5px;
        .first{
            color: #f27e3f;
          }
          .second{
              color: #fff;
            }
    }

  
}
.rdt_TableHeadRow{
    background: #f0f2fd !important;
    color: #3f53e0 !important;
    border-bottom: 2px solid #fff !important;
    .rdt_TableCol{
        .rdt_TableCol_Sortable{
            >div{
                white-space: normal;
            }
           
        }
    }
    
}
.rdt_TableBody{
    margin: 0 !important;
}
.rdt_TableRow{
    background: #fafafa !important;
}
.rdt_TableCol input,.rdt_TableCell input{
    margin: 0 !important;
   
}
.rdt_TableCol{
    border-right: 4px solid #fff !important;
    padding: 0 !important;
    margin: 0 !important;
    padding-left: 10px !important;
}
.rdt_TableCell{
    border-right: unset !important;
    padding: 0 !important;
    margin: 0 !important;
    border-bottom: 2px solid #fff !important;
}
.col{
    padding: 0;
}
.nav-tabs li{
    margin: 0;
}
.nav-item .nav-link {
    color: #bdc7e6;
    font-size: var(--xs);
    text-transform: uppercase;
    border-bottom: 3px solid #f0f2fd;
}
//Modal style
.body .modal-backdrop{
    max-width: 100%;
    background-color: var(--primary-1);
}
.body .modal-style{
    max-width: 100%;
    .modal-content{
        width: 50%;
        margin-top: 18%;
        border: unset;
    }
    .moadal-body{
        padding: 15px 10%;
    }
    .modal-title{
        color: #45485f;
    }
}
.btns-bar.dark button.paye{
    background:#fff;
    color: #9aafef;
    border: 2px solid;
}
body .btns-bar a.br-30.download.btn.btn-primary{
    background: #fff;
    color: #ff9800;
    border: 2px solid #ff9800;
    font-size: 12px;
    padding: 5px 12px;
}
.deverser-btn{
    display: flex;
    float: right;
    .first-btn{
        margin: 0;
        margin-right: 15px;
        margin-left: 5px;
        border-radius: 15px;
    }
    .file-name{
        margin: 0;
        margin-right: 15px;
        font-size: var(--small);
        color: #3f53e0;
    }
    .second-btn{
        margin: 0;
        margin-right: 15px;
        border-radius: 15px;
        background: #4caf503d;
    }
}


.old-file-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom:6px;
}
.item {
    border: 2px solid #222222;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    gap: 16px;
    align-items: center;
    will-change: transform;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
    width:80%;
  }
  
  .item:hover {
    border-color: #7e3af2;
    transform: scale(1.025);
  }
  
  .item svg {
    width: 36px;
    height: 36px;
    transition: all 0.3s ease-in-out;
  }
  
  .item:hover svg {
    color: #7e3af2;
    fill: red;
  }
  
  .item a {
    all: unset;
    margin-left: auto;
    background-color:#9791a1 ;
    padding: 12px 16px;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .item button:hover {
    background-color: #7126f1;
  }
  
  .item .filedata {
    display: flex;
    gap: 4px;
    font-size: 12px;
    margin-top: 8px;
    color: #888888;
  }
  
