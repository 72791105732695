@import "~bootstrap/scss/bootstrap";

.header{
    padding-top: 0px;
    .nav-container{
        padding: 10px;
        border-radius: 5px;
        box-shadow: rgb(162 155 225 / 25%) 0px 5px 15px;
    }
    .navbar-nav {
        gap: 25px;
    }
    .navbar-brand{
        width: 10%;  
    }
}
.top-header{
    padding-bottom: 0px;
.nav-container {
    padding: 0px;
    }
}
.vertical-header{

    background: var(--primary-1);
   /* width: 15%;*/
    gap:10px;
    a{
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #74768b;
    font-size: var(--xs);
    gap: 7px;
    }
    a:hover,a:focus{
        color: #fff;
        background: #74768b7d;
        border-radius: 10px;
        }
    a:active{
        color: #F27E3F;
        background: #74768b;
        border-radius: 10px;
        }
    
           
}
.vertical-header.nav-pills .nav-link.active {
    background-color: unset;
    color: #f27e3f;
    img.icon{
        display:none;
    }
    img.iconHover{
        display:block;
    }
}
.vertical-header.nav-pills .nav-link {
    background-color: unset;
    padding-left: 0;
    padding-right: 0;
    img.icon{
        display:block;
    }
    img.iconHover{
        display:none;
    }
}
.nav-container{
    font-size: var(--small);
}
@media (min-width: 1400px){
    .navbar-expand-xxl .navbar-nav .nav-link {
        padding: 10px 15px;
}
}
.nav-btn{
    background: #f2eaff;
    border-radius: 5px;
    padding: 10px 25px;
}
.vertical-header a.reverse{
    display: flex;
    flex-direction: row-reverse;
}
.vertical-header a.last{
    padding: 40% 0px;
    margin-left: 10px;
}


