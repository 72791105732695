.container-radio{
     display: flex;
     font-size: var(--large);
     gap: 5px;
     font-weight: 600;
    .form-check-input:checked {
        background-color: #0da781;
        border-color: #0da781;
    }
    span{
        color:#0da781 ;
        font-size: var(--medium);
    }
}
.Employeur-tabs .form-floating .form-control{
    background-color: green;
}
