
@media only screen and (max-width: 600px) {
    .login-card {
        width: 100%;
        height: 100%;
        padding: 5px;
        padding-bottom: 20%;
    }
    .left-grid{
        width: 100%;
        img{
            width: 50%;
            margin :20% 20%;
        }
    }
    .right-grid {
        padding: 0px;
    }
    .pad-form{
        padding: 0px 15px;
    }
    

}