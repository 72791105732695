.card-content{
    display: flex;
    flex-direction: inherit;
    border: unset;
    margin-top: 10px;
    box-shadow: rgb(232 238 254) 0px 7px 29px 0px;
    .salary-container{
        width: 90%;
    }
    .tab-content{
        margin: 0;
        width: 100%;
    }
    .form-control{
        font-size: var(--small);
    }
    .table-adress{
        font-size: var(--small);
        tr{
           background:#fafafa;
        }
        th{
            color: #3f53e0;
            background: var(--light);
            font-weight: 500;
            margin: 0;
            min-height: 35px;
        
        }
    }
    .btns-bar.dark{
        button{
            color: #fff;
        }
        button.orange{
            color: #f27e3f;
        }
    }
    .btns-bar{
        margin: 0;
        width: 100%;
        text-align: end;
        button{
            margin-right: 5px;
            font-size: var(--xs);
            padding: 5px 10px;
            background: var(--light); 
            color:#45485f;
            border: unset;
            span{
                background-position: 1px 6px;
                background-repeat: no-repeat;
                background-size: 10px;
                display: inline-block;
                width: 13px;
                height: 18px;
            }
        }
        button:hover{
         span{
                background-position: 1px 6px;
                background-repeat: no-repeat;
                background-size: 10px;
                display: inline-block;
                width: 13px;
                height: 18px;
            }
        }
        .add{
           background: var(--light); 
           color:#45485f;
           border: unset;
           .Icon{
            background-image: url("../../../public/assets/dark-add.svg");
           }
          
        }
        .edit{
            background: var(--light); 
            color:#45485f;
            border: unset;
            .Icon{
                background-image: url("../../../public/assets/dark-edit.svg");
         }
           
         }
         .delete{
            background: var(--light); 
            color:#45485f;
            border: unset;
            .Icon{
                background-image: url("../../../public/assets/dark-delete.svg");
            }
           
         }
        .add:hover{
            background: var(--primary-1); 
            color:#fff;
            .Icon{
                background-image: url("../../../public/assets/add.svg");
            }
         }
        .edit:hover,.delete:hover{
            background: var(--primary-1); 
            color:#fff;
            .Icon{
                background-image: url("../../../public/assets/edit.svg");
            }
         }
        .delete:hover{
            background: var(--primary-1); 
            color:#fff;
            .Icon{
                background-image: url("../../../public/assets/delete-white.svg");
            }
         }
      
         }
    }

.Dash-tabs{
    --bs-nav-tabs-link-active-bg: #898988;
    --bs-nav-tabs-link-active-border-color: #dee2e6;
    --bs-nav-tabs-active-border-radius: 30px;
    .nav-item{
        margin:0;       
        padding-bottom: 10px;
        .nav-link.active{
            background-color: #f4f7ff;
            border:unset;
            color: #8d8e8f;
        }
        .nav-link.active:hover{
            background-color: #f4f7ff;
            border:unset;
            color: #8d8e8f;
        }
        .nav-link{
            padding: 4px 10px;
            border-radius: 30px;
            background-color: unset;
            border:unset;
            color: #bdc7e6;
            font-size: var(--xs);
            text-transform: uppercase;
        }
        .row .nav-link:hover{
            background-color: #bfcdf1;
            color: #8d8e8f;
        }
     
        
    }
  

}
.cards{
    img.iconHover{
        display: block;
       }
       img.icon{
        display: none;
       }
    background: linear-gradient(var(--cnss-color), #fff) ;
    border: unset;
    width: '18rem' ;
    display: flex;
    flex-direction: inherit;
    .card-body{
        margin-left: 15px;
    .card-title{
        color: var(--primary);
        font-size: 18px;
        font-weight: 700;
    }
    }
    
}
.cards:hover{
   color: #fff;
    .card-title{
        font-size: 19px;
        font-weight: 700;
        color: #fff;
     
}
img.icon{
    display: block;
   }
   img.iconHover{
    display: none;
   }

}

.cards.card1:hover{
    background: linear-gradient(#f38344, #fff3a8);
    margin-bottom: 10px;
    box-shadow: #f3834459 0px 7px 21px 0px;
    
}

.cards.card2:hover{
    background: linear-gradient(#1eceac, #8dd9f4);
    margin-bottom: 10px;
    box-shadow: #1eceac80 0px 7px 21px 0px;
}
.cards.card3:hover{
    background: linear-gradient(#3f53e0, #9eddfd);
    margin-bottom: 10px;
    box-shadow: #3f53e085 0px 7px 21px 0px;
}
.cards.card4:hover{
    background: linear-gradient(#9b07ff, #a6a6ff);
    margin-bottom: 10px;
    box-shadow: #9b07ff59 0px 7px 21px 0px;
}
.cards.card5:hover{
    background: linear-gradient(#fe0745, #f09dee);
    margin-bottom: 10px;
    box-shadow: #fe074566 0px 7px 21px 0px;
}
.cards.card6:hover{
    background: linear-gradient(#11ce96, #eff26b);
    margin-bottom: 10px;
    box-shadow: #11ce9657 0px 7px 21px 0px;
}

.matricule{
    padding: 10px;
    border: 1px solid #ebf0fe;
    border-radius: 5px;
    font-size: var(--xs);
    padding-right: 5%;
    .solde{
        font-size: 15px;
        font-weight: bold;
        color: var(--bs-link-color)
    }
}
.Employeur-tabs{
    border-bottom: 2px solid #ebf0fe;
    .nav-item{
        margin:0;
        .nav-link{
            color: #bdc7e6;
            font-size: var(--xs);
            text-transform: uppercase;
        }   
        .nav-link:active{
            color: #132149;
            font-size: var(--xs);
        }     
      
     
    }
   
}
tr,th{
    border-bottom: 4px solid #fff;
    border-right: 4px solid #fff;
}


.active-btn{
    border: 1px solid orange;
}

.nav-tabs .nav-link.active {
    color: #f27e3f;
    border-bottom: 3px solid #f27e3f;
    border-right-color: #fff;
    border-left-color: #fff;
    border-top-color: #fff;
}
.nav-tabs .nav-link.active:hover {
    color: #f27e3f;
    border-bottom: 3px solid #f27e3f;
    border-right-color: #fff;
    border-left-color: #fff;
    border-top-color: #fff;
}
.nav-tabs .nav-link:hover{
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-bottom: 3px solid #f0f2fd;
}
.parent{
    padding: 10px;
}
.card-content.mobil{
    display: none;
  }
  .btns-bar.mobil{
    display: none;
}
.search-bar{
    width: 35%;
}
.container-cards{
    .nav{
        gap: 10px;
    }
    
    .nav-link{
        padding: 0;
        background-color: unset !important;
        color: #000 !important;  
    }
}
.first{
    width: 50%;
}
form .form-row{
    margin-top: 15px;
}
.nav-tabs{
    border: unset;
}
.salary-card{
    border: unset;
    background: #f0f2fd;
    height: 95%;
}
// table scrolling 
.container{
    padding: 1rem;
    margin: 1rem;
  }
.table-scroll{
    display: block;
    empty-cells: show;
    border: unset;
    box-shadow: rgb(240 242 253) 0px 7px 29px 0px;
    padding: 0;
    
  }
 
.table-scroll thead{
    background-color: #f1f1f1;  
    position:relative;
    display: block;
    width:100%;
    overflow-y: scroll;
  }
  
.table-scroll tbody{
    /* Position */
    display: block; position:relative;
    width:100%; overflow-y:scroll;
  }
  
.table-scroll tr{
    width: 100%;
    display:flex;
  }
  
.table-scroll td,.table-scroll th{
    flex-basis:100%;
    display: flex;
    padding: 5px 10px;
    margin: 0;
}

  /* Other options */
  
.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child{
    flex-basis:100%;
    flex-grow:1;
}
.table-scroll.declarations.small-first-col td:first-child,
.table-scroll.declarations.small-first-col th:first-child{
    flex-basis:10%;
    flex-grow:1;
}
  
.table-scroll tbody tr:nth-child(2n){
    background-color: rgba(130,130,170,0.1);
}

.body-half-screen{
    max-height: 50vh;
}
  
.small-col{flex-basis:10%;}

.fix-height{
    height: 330px;
}
.success-alert {
    border: 2px solid #b0bbe1;
    padding: 10%;
}
body .right-icon-table{
    margin-left: 20%;
    position: absolute;
}
body .dec-btn-salary{
    position: relative;
    right: 10px;
    margin-bottom: 5%;
}
body .components-salary{
    width: 92%;
    margin: 0;
}