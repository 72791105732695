@import "../../Theme/variables.scss";

.margin-auto{
    margin: auto;
}
.left-grid{
    height: 70%;
    width: 95%;
    text-align: center;
    margin: 0px 13px;
    background: linear-gradient(var(--cnss-color), #fff);
    border-radius: 10px;
    margin-bottom: -20px;
    img{
        width: 75%;
        margin: 27% 15%;
    }
}
.right-grid{
    height: 100%;
    padding: 45px;
    padding-left: 5px;
    padding-left: 30px;
  
}
.login-card{
    width: 796px;
    height: 635px;
    padding: 5px;
    border: unset;
    box-shadow: rgb(162 155 225 / 25%) 0px 5px 15px;
    border-radius: 10px;
}

.mt-4r{
    margin-top: 4.5rem ;
}
.submit{
    background: var(--primary);
    border: unset;
}
.form-floating > label {
    padding: 10px 0.75rem;
    font-size: 12px;
    color: #afb6e5;
}
.form-floating >.form-control{
   border: 2px solid #eaeffe;
   height: calc(2.5rem + 2px);
   
}
.eye{
    margin-top: 1%;
    position: absolute;
    width: 40%;
    right: 5px;
    text-align: end;
    color: #c6d4f7;
    font-size: var(--large);
}
.eye:hover{
  
    color: var(--primary);
}
.title-form{
    color: #45485f;
    font-weight: 700;
    font-size: 36px;
}
.title-form.msg-virement{
    font-size: 24px;
}
.subtitle-form{
    color:#808080;
}
@media (max-width: 1400px){
    .right-grid {
        height: 100%;
        padding-left: 30px;
    }
}
@media (min-width: 1500px){
    .right-grid {
        height: 100%;
        padding: 45px;
        padding-left: 30px;

    }
}

.sm{
   font-size: var(--small); 
}
.xs{
    font-size: var(--xs); 
 }
.btn{
    padding: 10px 20px;
 }
 .btn.submit:hover{
   background-color: var(--primary);
 }

.second-color{
  color:  var(--second-primary-cl);
}
.text-in-line{
    position: absolute;
    margin: -12px -30px !important;
    padding: 0px 21px;
}
.pl-5{
    padding-left:5px ;
}
.valider{
    border: unset;
    background: #e3f9f4;
    color: var(--succes);
}
.valider:hover{
    background: #ade3d7;
    color: var(--succes);
}

.form-floating > .form-select{
    height: 40px;
    padding: 0;
    padding-left: 10px;
    font-size: var(--xs);
    border: 2px solid #eaeffe;
}
select.pl-1.select.form-select:valid {

    border-color: #e8f0fe;
    background-image: unset;
}
.was-validated .form-select:valid:not([multiple]):not([size]){
    background-image: unset;
}

.form-floating > .form-control.invalid,.form-select.invalid{
    border-color: #dc3545;
    background-image: unset;
}
.form-floating > .form-control.invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem #f443365e;
}
.valide.form-control:valid{
    border-color:#c6d4f7 !important;
    background-image: unset;
  }
  .form-floating > .form-control.valide:focus{
    border-color:#c6d4f7;
    box-shadow: 0 0 0 0.25rem rgb(198 212 247 / 28%);
    background-image: unset;

    
  }
  .scroll-form{
    overflow-y: auto;
    max-height: 400px;
  }
  
  svg.sadImotc{
    font-size: 8rem;
    margin-top: 10%;
    color: #aebae0;
  }
  .upload-url{
    text-decoration: underline;
    color: #198754;
  }
  .eye.config{
    width: 10%;
  }
  /* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
