@import "./Theme/variables.scss";
.body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
      background-image: url("/background.png");
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
  }
body.body * {
    max-width: 1200px;
    margin: auto;
}

.float-right{
    float: right;
 }
.uppercase{
    text-transform: uppercase;
}
.underline-unset{
    text-decoration: unset;
 }
.bg-white{
    background: #fff;
}
.align-center{
    text-align: center;
}
.align-right{
    float: right;
   
}
.align-left{
    float: left;
    
}
$heart-wrapper-size: 200px;
$heart-size: 80px;
$heart-small-size: 100px;
$green-color: rgba(94, 224, 163, 0.8);
$pink-color: rgba(232, 143, 201, 0.8);
$blue-color: rgba(134, 193, 232, 0.8);
$purple-color: rgba(189, 138, 234, 0.8);
$yellow-color: rgba(234, 229, 131, 0.8);
$orange-color: rgba(232, 158, 129, 0.8);
$parent-animation: parent-anim 5s linear infinite;
$parent-animation-2: parent-anim-2 5s linear infinite;
$child-animation: child-anim 5s linear infinite;
$child-animation-2: child-anim-2 5s linear infinite;
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
      @content;
    }
    @-moz-keyframes #{$animation-name} {
      @content;
    }
    @-ms-keyframes #{$animation-name} {
      @content;
    }
    @-o-keyframes #{$animation-name} {
      @content;
    }
    @keyframes #{$animation-name} {
      @content;
    }
}

body {
  .hearts_wrapper {
    width: 100%;
    height: 500px;
    position: fixed;
    display: block;
    margin: auto;
    text-align: center;
    z-index: -1;
    
    .heart {
    text-decoration: none;
    display: block;
    position: relative;
      &.green {
        left: 50px;
        animation: $parent-animation;
        transform-origin: 50% 120px;
         div {
          font-size: $heart-size;
          color: $green-color;
          animation: $child-animation;
        }
      }
      &.pink {
        left: 30px;
        margin-top: -120px;
        animation: $parent-animation-2;
        transform-origin: 50% 125px;
        > div {
          font-size: $heart-size;
          color: $pink-color;
          animation: $child-animation-2;
        }
      }
      &.blue {
        margin-top: -240px;
        left: 220px;
        animation: $parent-animation;
        transform-origin: 50% 120px;
        > div {
          font-size: $heart-size;
          color: $blue-color;
          animation: $child-animation;
        }
      }
      &.purple {
        margin-top: -280px;
        left: 290px;
        animation: $parent-animation;
        transform-origin: 40% 150px;
        > div {
          font-size: $heart-size;
          color: $purple-color;
          animation: $child-animation;
        }
      }
      &.small {
        &.yellow {
          margin-top: -90px;
          left: 270px;
          animation: $parent-animation;
          transform-origin: 40% 150px;
          > div {
            font-size: $heart-small-size;
            color: $yellow-color;
            animation: $child-animation;
          }
        }
        &.orange {
          margin-top: -10px;
          left: 180px;
          animation: $parent-animation;
          transform-origin: 40% 150px;
          > div {
            font-size: $heart-small-size;
            color: $orange-color;
            animation: $child-animation;
          }
        }
      }
    }
  }
}

  @keyframes parent-anim {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes parent-anim-2 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes child-anim {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes child-anim-2 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .mt-10{
    margin-top:10% !important;
  }
  .mt-15{
    margin-top:15% !important;
  }
  .mt-20{
    margin-top:20% !important;
  }
  .mb-20{
    margin-bottom:20% !important;
  }
  .br-15{
    border-radius:15px  !important;
  }
  .br-30{
    border-radius:30px  !important;
  }
  .bg-light{
    background-color: var(--light) ;
  }

 .badge-succes.bg-primary{
    background-color: #e3f9f4 !important;
    color: #0da781;
 }
 .badge-warning.bg-primary{
  background: #ffece2 !important;
  color: #f27e3f;
}
.badge-info.bg-primary{
    color: #9ca8cd;
    background: #f0f2fd !important;
    border: 2px solid #aebae0;
}

.badge{
  font-size: 14px;
  font-weight: 600;
}
.bg-primary-1{
  background: var(--primary-1) !important;
  border: unset !important;
}
.bg-gray{
  background: var(--gray) !important;
  border: unset !important;
}
.f-l{
  font-size: var(--large);
}

body .f-w{
  font-weight: 600;
}
body .c-primary-1{
  color: var(--primary-1);
}
body .c-primary{
  color: var(--primary);
}
.flex{
  display: flex;
}
.block{
  display: block;
}
.pt-5{
padding-top: 5%;
}
.mb-15{
  margin-bottom: 15%;
}
.hide{
  display: none !important;
}
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #c6d4f7 !important;
  background-image: unset !important;
}
.err{
  font-size: var(--xs);
}
.btn.back-btn{
    color: #c6d4f7;
    font-weight: 500;
    background: unset;
    border: unset;
    img{
      padding-right: 5px;
    }
}
.right-grid .btn.back-btn:hover{
   background-color: unset;
   color: #c6d4f7;
  }

button.mt-4.uppercase.back-btn.small.align-left.btn.btn-light:hover{
    background: unset;
    border: unset;
    color: #c6d4f7;

}
button.btn-light.download{
    background: unset;
    border: 3px solid #aebae0;
    color:#aebae0 ;

}
.form-floating .invalid-feedback {
	font-size: var(--xs);
  text-align: start;
  padding-left: 10px;
}
.gap{
  gap: 10px;
}
.btn .icon-size{
  font-size: var(--medium);
  margin-right: 5px;
}
.badge .icon-size{
  font-size: var(--medium);
}
.scroll-table{
  width: 970px;
  overflow-x: scroll;
}
body .form-check-input:checked {
  background-color: #F27E3F;
  border-color: #F27E3F;
}
body .form-check-input:focus {
  border-color: #F27E3F;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(242 126 63 / 31%);
}
.invalid-feedback{
  text-transform: lowercase;
}
.required{
  color: #dc3545;
  text-align: center;
}
body .was-validated .form-select:valid{
  border-color: #c6d4f7;
}
body .form-floating > .form-control.textArea-height{
  height: 10%;
}
.f-xs{
 font-size: var(--xs);;
}
body a.paye{
  background: #fff;
  color:#a8b3f3;
  font-size: 13px;
  padding: 5px 10px;
  border: 2px solid #a8b3f3;
}
body a.paye:hover{
  background: #a8b3f3;
  color:#fff;
  border: 2px solid #fff;
}


.loader {
  width: 60px;
}

.loader-wheel {
  animation: spin 1s infinite linear;
  border: 2px solid rgba(30, 30, 30, 0.5);
  border-left: 4px solid #fff;
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  width: 50px;
}

.loader-text {
  color: #fff;
  font-family: arial, sans-serif;
}

.loader-text:after {
  content: 'Loading';
  animation: load 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    content: 'Loading';
  }
  33% {
    content: 'Loading.';
  }
  67% {
    content: 'Loading..';
  }
  100% {
    content: 'Loading...';
  }
}