$mainColor: #f0f2fd;
$baseColor: #fff;



.wizard-progress{
  display: table;
  table-layout: fixed;
  position:relative;
  margin-bottom: 5% !important;
  
  .step{
    display: table-cell;
    text-align: center;
    vertical-align: top;
    overflow: visible;
    position:relative;
    font-size: var(--xs);
    color: #b7c1e3;
    
    &:not(:last-child):before{
      content: '';
      display:block;
      position: absolute;
      left: 50%;
      top: 37px;
      background-color: $baseColor;
      height: 6px;
      width: 100%;
    }
    
    .title-wizard.valideted{
      color: #00cb99;
    }
    .node{
      display: inline-block;
      border: 6px solid $baseColor;
      background-color: #00cb99;
      border-radius: 18px;
      height: 30px;
      width: 30px;
      position: absolute;
      top: 25px;
      left: 50%;
      margin-left: -18px;
    }
    
    &.complete{
      &:before{
        background-color: $mainColor;
      }
      .node{
        border-color: $mainColor;
        background-color: $mainColor;
        
      
      }
    }
    
    &.in-progress{
      &:before{
        background: $mainColor;
   
      }
      .node{
        border-color: $mainColor;
      }
    }
  }
}
.wizard-progress .step.in-progress.pending:before {
  background: #f0f2fd;
  color: #f0f2fd;
}

.wizard-progress .step.in-progress .node.pending{
  background: #f0f2fd;
    border-color: #f0f2fd;
}
.wizard-progress .step.in-progress .node.green.pending{
  background: #00cb99;
  border-color:#e3f9f4;
}
.wizard-progress .step.in-progress .node.valideted{
  background: #00cb99;
}
.wizard-progress .step.in-progress.valideted:before {
  background: #00cb9938;
}
@media only screen and (max-width: 600px) {

    .wizard-progress {
        display: table;
        width: 100%;
        table-layout: fixed;
        position: relative;
        left: 0;
        margin-bottom: -21% !important;
    }

    }