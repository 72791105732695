.pdf{
   position: fixed;
    padding-top: 10% !important;
  //  width: 70%;
    margin: 0;
    .paragraph{
        font-size: 14px;
        padding-right: 1%;
        padding-left:10px;
        
    }
    .table{
        width: 81%;
    }
    .card-matricule{
        line-height: 2rem;
        border: 2px solid black;
        padding: 10px;
        font-size: 14px;
    
    }
    .card-matricule.padding{
        padding-right: 4px;
    }
    .pr-15{
        padding-right: 13%;
    }
    .pr-20{
        padding-right: 20%;
    }
    .border-t{
        border-top: 2px solid;
    }
    .border-3{
        border: 2px solid;
        border-right: unset;
    }
    tr.declarer, th {
       border-bottom: 1px solid #000;
       border-right: 1px solid #000; 
    }
    tr.payment {
      border: unset;
     }
    td{
        font-size: var(--xs);
    }
    table{
        border: #000;
    }
    .padding-toRight{
        padding: 10px 10px 10px 60px;
    }
}
