@media only screen and (max-width: 600px) {

.card-content.mobil .row > div  {
    padding-left: 5px;
    padding-right: 5px;
}
.toggle{
    width: 21%;
    margin-bottom: 0px;
}
.right-container{
    border:unset;
}
.switch{
    margin-bottom:0px;
}
}